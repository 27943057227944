<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <v-row>
        <v-col md="2">
          <v-btn v-if="returnAbility('parent:store')" color="green" class="white--text"
            @click="$router.push('/addUser/parent')">
            <v-icon size="20">mdi-plus</v-icon>
            {{ $t("add parent") }}
          </v-btn>

          <!-- <app-add-subject @submitted="fetchData(1)"></app-add-subject> -->
        </v-col>
        <!-- <v-col md="1">
          <v-btn
            v-if="returnAbility('parent:store')"
            color="green"
            class="white--text"
            @click="downloadExportedFile"
          >
            <v-icon size="20">mdi-plus</v-icon>التصدير
          </v-btn>
        </v-col> -->

        <v-col md="3">
          <v-text-field v-model="name" :label="$t('search by name')" dense outlined hide-details></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field v-model="username" :label="$t('search by user name')" dense outlined
            hide-details></v-text-field>
        </v-col>
        <v-col md="3" class="d-flex justify-center align-center">
          <v-text-field v-model="phone" :label="$t('search by phone number')" dense outlined
            hide-details></v-text-field>
          <v-icon class="mr-2" @click="fetchData(1)">mdi-magnify</v-icon>
        </v-col>
      </v-row>
      <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData"
        @re-fetch-paginated-data="fetchData($event)" :enableDelete="false" :flaggedItems="flaggedItemsData">
        <template slot="editFeature" slot-scope="{ row }">
          <v-icon @click="$router.push(`/editUser/parent/${row.id}`)" v-if="returnAbility('parent:update')"
            color="info">mdi-pencil</v-icon>
          <v-icon color="green" @click="$router.push(`/showUser/parent/${row.id}`)"
            v-if="returnAbility('parent:show')">mdi-eye</v-icon>
          <deleteUser @record-deleted="fetchData(1)" class="py-2" path="parent" :id="row.id" />
        </template>
      </app-base-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import deleteUser from "../../../components/core/delete.vue";
export default {
  components: { deleteUser, },
  data() {
    return {
      submitBtnLoading: false,
      name: "",
      phone: null,
      username: "",
      flaggedItemsData: [
        "created_at",
        "email_verified_at",
        "image",
        "token",
        "type",
        "updated_at",
        "children",
      ],
    };
  },
  computed: {
    ...mapGetters({
      tableOptions: "parents/getTableOptions",
    }),
  },
  methods: {
    ...mapActions({
      fetchParents: "parents/fetchParents",
    }),

    fetchData(page) {
      this.fetchParents({
        params: {
          page,
          name: this.name,
          phone: this.phone,
          username: this.username,
        },
      });
    },

    async downloadExportedFile() {
      let url = "";
      window.location.href = "";
    },
  },
  created() {
    this.fetchData(1);
  },
};
</script>

<style></style>
